@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Boogaloo');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display&display=swap');

.App {
  text-align: center;
}


$col-red: #D91722;
$col-violet: #983796;
$col-bleu: #0A7588;
$col-orange: #FFDF96;
$col-jaune: #FFF7E6;

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: $col-violet;
}

a {
  text-decoration: underline $col-red !important;
  color: black;
  &:hover {
    color: red;
  }
}

strong {
  background: $col-bleu;
  font-weight: bold;
  padding: 0 0.2ex;
  color: white;
}

h1 {
  margin-top: 1em;
  font-family: 'Boogaloo', 'sans-serif';
  display: table;
  background: $col-red;
  color: white;
  padding: 0 0.5ex;
  text-transform: uppercase;
  z-index: 1000;
  position: relative;
  margin-left: 1em;
}

h2 {
  font-family: 'Boogaloo', 'sans-serif';
  color: $col-red;
}

header {
  background: $col-jaune;
  .logo-header {
    height: 5em;
  }
  nav {
    text-transform: uppercase;
    font-family: 'Boogaloo', 'sans-serif';
    font-size: Large;
    background: $col-jaune;
    img {
      height: 15vh;
    }
  }
}


.home-page {
  background: $col-violet;
  margin-top: 10vh;
  padding: 2em;
}

#news-list {
  display: block;
}

main {
  background: $col-violet;
  margin-top: 15vh;
  margin-bottom: 4em;
  font-family: 'Noto Sans Display', sans-serif;

  .article-content {
    background: white;
    padding: 1em;
    margin-top: -1em;
    img[alt=Facebook], img[alt*=Téléphone], img[alt=Mail] {
      padding: 0;
      height: 1.5em;
    }
    img[alt="Mairie de Nanterre"] {
      padding: 0;
      height: 3em;
    }
    img {
      padding: 3em;
    }
  }

  .content-images {
     background: white;
     padding: 1em;
    margin-top: -1em;
  }
  blockquote {
    padding: 1em;
    border-left: 5px solid $col-bleu;
    color: black;
    font-weight: bold;
  }
}

